<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        人工审核
      </template>
      <template #input>
        <!-- <span class="tag">标签名称：</span> -->
        <a-input placeholder="请输入姓名" v-model="queryParams.name" />
        <a-input placeholder="请输入手机号" v-model="queryParams.mobile" />
        <a-input placeholder="请输入身份证号" v-model="queryParams.idcard" />
        <a-select placeholder="选择考试" allowClear v-model="queryParams.configId">
          <a-icon slot="suffixIcon" type="caret-down" />
          <a-select-option
            v-for="item in examList"
            :key="item.configId"
            :value="item.configId"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
        <a-button type="primary" class="btn" @click="search()">搜索</a-button>
      </template>
    </HeaderBox>

    <!-- 表格数据 -->
    <a-table
      class="table-template"
      :rowKey="(item,index) => index"
      :columns="columns"
      :data-source="tableData"
      :scroll="{ x: 1700 }"
      :loading="loading"
      @change="onPage"
      :pagination="{
        total: total,
        current: queryParams.current,
        defaultPageSize: queryParams.size,
        showSizeChanger: true,
        showTotal: function (total, range) {
          return `共${total}条`;
        },
      }"
    >
      <template slot="index" slot-scope="item, row, i">
        <div style="text-align: center;">
          {{ (queryParams.current - 1) * 10 + i + 1 }}
        </div>
      </template>
      <template slot="operation" slot-scope="item">
        <div class="btn_router_link">
          <a @click="getDetail(item)">审核</a>
          <template> | </template>
          <a @click="gooR(item)">操作记录</a>
        </div>
      </template>
    </a-table>

    <!-- 详情弹框 -->
    <a-modal
      v-model="detailShow"
      align="center"
      title="审核详情"
      :footer="null"
      width="980px"
    >
    <div class="flex">
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <img :src="detailData.certifiMobile" />
        <span>证件照</span>
      </div>
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <img :src="detailData.reversePicture" />
        <span>身份证反面照片</span>
      </div>
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <img :src="detailData.frontPicture" />
        <span>身份证正面照片</span>
      </div>
    </div>
    <div class="flex">
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <img :src="detailData.heldPhotos"  />
        <span>手持身份证照片</span>
      </div>
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <img :src="detailData.validPhoto" />
        <span>抓拍失败照片</span>
      </div>
      <div class="ant-upload ant-upload-select ant-upload-select-picture-card div_item">
        <video controls style="width: 280.33px;height: 220px;" v-if="detailShow" >
          <source :src="detailData.validVideo" type="video/mp4">
        </video>
        <span>抓拍失败视频</span>
      </div>
    </div>
    <div class="div_item">
        <a-row >
          <a-col :span="3" ><span class="name">审核操作：</span></a-col>
          <a-col :span="6">
            <a-radio-group v-model="auditData.status">
              <a-radio style="width:80px" :value="1">通过</a-radio>
              <a-radio :value="2">驳回</a-radio>
            </a-radio-group>
          </a-col>
        </a-row>
        <p style="margin: 10px;"></p>
        <a-row v-show="auditData.status==2" >
          <a-col :span="3" ><span class="name">驳回原因：</span></a-col>
          <a-col >
            <a-textarea
              v-model="auditData.rejectReason"
              style="width:80%" :maxlength="300"
              placeholder="请输入驳回原因，将在考试端显示。"
              :auto-size="{ minRows: 3, maxRows: 5 }"
            />
          </a-col>
        </a-row>
    </div>
    <div class="detail_btn">
      <a-button type="primary" class="btn" @click="auditClick(detailData.examineeId)">确认</a-button>
      <a-button class="btn" @click="closeWin">取消</a-button>
    </div>
    </a-modal>
  </div>
</template>

<script>
import HeaderBox from '@/components/HeaderBox.vue'
const columns = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    fixed: "left",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "姓名",
    width: "200px",
    fixed: "left",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "性别",
    fixed: "left",
    align: "center",
    dataIndex: "sex",
  },
  {
    title: "手机号",
    width: "200px",
    fixed: "left",
    align: "center",
    dataIndex: "mobile",
  },
  {
    title: "身份证号",
    width: "200px",
    fixed: "left",
    align: "center",
    dataIndex: "idcard"
  },
  {
    title: "考试名称",
    width: "260px",
    fixed: "left",
    align: "center",
    dataIndex: "examName",
  },
  {
    title: "考试日期",
    width: "200px",
    align: "center",
    dataIndex: "examDate",
  },
  {
    title: "考试场次",
    width: "200px",
    align: "center",
    dataIndex: "timeDate",
  },
  {
    title: "申请时间",
    width: "200px",
    align: "center",
    dataIndex: "applyTime",
  },
  {
    title: "操作",
    align:'center',
    width: "160px",
    scopedSlots: { customRender: "operation" }
  }
];

const columnsDetail = [
  {
    title: "序号",
    width: "80px",
    align: "center",
    dataIndex: "index",
    scopedSlots: { customRender: "index" },
  },
  {
    title: "问题",
    align: "center",
    dataIndex: "keyname",
    scopedSlots: { customRender: "keyname" },
  },
  {
    title: "反馈",
    align: "center",
    dataIndex: "value",
  },
];

export default {
  components: { HeaderBox },
  data() {
    return {
      columns,
      defaultColumns: null,
      columnsDetail,
      loading: false,
      tableData: [],
      total: 0,
      queryParams: {
        current: 1, // 页码
        size: 10, // 页数
        courseId: undefined, // 课程id
        name: '',
        mobile: ''
      },
      examList: [],
      detailShow: false,
      intervalId:null,
      auditData:{
        status :null,
        rejectReason:null
      },
      detailData: {}, // 详情信息
      defualtImg : 'https://cos.hxclass.cn/prod/template/user/20241121-163000.jpg'
    }
  },
  methods: {
    // 搜索
    search() {
      this.queryParams.current = 1;
      this.getManageList();
    },
    // 分页
    onPage(e) {
      this.queryParams.current = e.current;
      this.queryParams.size = e.pageSize;
      this.getManageList();
    },
    // 查询列表
    getManageList() {
      this.loading = true;
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/examinee/getManualReviewList",
        params: this.queryParams,
      }).then((res) => {
        this.loading = false;
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records;
          this.total = res.data.total;
        } else {
          this.tableData = [];
          this.total = 0;
        }
      });
    },
    // 查询课程列表---下拉框
    getExam() {
      this.$ajax({
        url: "/hxclass-management/exam/config/exam/tab/list",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.examList = res.data;
        }
      });
    },
    // 详情
    getDetail(e) {
      this.$ajax({
        method: "get",
        url: "/hxclass-management/exam/examinee/getManualReviewDetaill/"+e.examineeId
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.detailData = res.data;
          if(this.detailData.validVideo == null){
            this.detailData.validVideo = this.defualtImg;
          }
          if(this.detailData.certifiMobile == null){
            this.detailData.certifiMobile = this.defualtImg;
          }
          if(this.detailData.reversePicture  == null){
            this.detailData.reversePicture = this.defualtImg;
          }
          if(this.detailData.frontPicture   == null){
            this.detailData.frontPicture  = this.defualtImg;
          }
          if(this.detailData.heldPhotos == null){
            this.detailData.heldPhotos = this.defualtImg;
          }
          if(this.detailData.validPhoto == null){
            this.detailData.validPhoto = this.defualtImg;
          }
          this.detailShow = true;
        } else {
          this.detailData = {};
        }
      });

      // 清空页面再次进入缓存数据
      this.auditData.status=null;
      this.auditData.rejectReason=null;
    },
    auditClick(examineeId){
      if(this.auditData.status == null){
         this.$message.warning("请选择审核状态");
         return;
      }
      if(this.auditData.status == 2 && this.auditData.rejectReason == null){
         this.$message.warning("请输入驳回原因");
         return;
      }
      this.$ajax({
        method: "put",
        url: "/hxclass-management/exam/examinee/auditManualReview/"+examineeId+"?status="+this.auditData.status+"&rejectReason="+this.auditData.rejectReason
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success(res.message);
          this.detailShow=false;
          this.getManageList();
        } else{
          this.$message.warning(res.message);
        }
      });
    },
    closeWin(){
      this.detailShow=false;
      this.auditData.status = null;
      this.auditData.rejectReason = null;
    },
    startPolling() {
      // 设置定时器，每分钟调用fetchData方法
      this.intervalId = setInterval(this.getManageList, 40000);
      // 立即执行一次请求
      this.getManageList();
    },
    stopPolling() {
      // 清除定时器
      if (this.intervalId) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    },
    // 查看用户操作记录
    gooR(e) {
      this.$router.push({
        path: "/admin/Examination/operationRecord",
        query: { examineeId: e.examineeId },
      });
    },
  },
  created() {
    this.defaultColumns = JSON.parse(JSON.stringify(columns))
    this.startPolling();
    this.getExam();
  } ,
  // 生命周期-实例销毁前调用
  beforeDestroy () {
    this.stopPolling();
  }
}
</script>

<style scoped lang="less">
  .userMess{
    display: flex;
    margin-bottom: 15px;
    flex-wrap: wrap;
    p{
      margin-right: 20px;
    }
    p:last-child{
      margin-right: 0;
    }
  }
  .flex {
    max-width: 1000px;
    display: flex;
    justify-content: space-between;
    >.div_item {
      width: 300px;
      height: 220px;
      padding: 10px;
    
      >span{
        display: inline-block;
        margin-top: 10px;
        font-size: 14px;
      }
      >img {
        width: 100%;
        height: 220px;
        background-repeat: no-repeat;
        -o-object-fit: cover;
        object-fit: cover;
      }
    }
}

.detail_btn {
  margin-top: 30px;
  > .btn {
    margin: 10px;
  }
}
</style>