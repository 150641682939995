import { render, staticRenderFns } from "./ManualReview.vue?vue&type=template&id=4ef12b72&scoped=true&"
import script from "./ManualReview.vue?vue&type=script&lang=js&"
export * from "./ManualReview.vue?vue&type=script&lang=js&"
import style0 from "./ManualReview.vue?vue&type=style&index=0&id=4ef12b72&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef12b72",
  null
  
)

export default component.exports